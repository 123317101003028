import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const BlogPostItemWrapper = styled.section`
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to right, #C58BC3, #7880EC );
    padding: 10px;
    margin: 10px;
    display: block;

    h1 { 
       text-align: center
    }
`   

const BlogPostItemImageWrapper = styled.div`
    display: flex;
    margin: 0 auto;
`

const BlogPostImage = styled(Img)`
    display: flex !important;
    margin: 0 auto !important;
    max-width: 400px;
    max-height: 400px;
    width: 100%;
    height: 100%;
`

const BlogPostItemContentWrapper = styled.div`
    flex-grow: 1;
    padding-left: 8px;
`



const BlogPostItem = ({ 
    authorName, 
    authorTitle, 
    blogPostImage,
    blogPostTitle, 
    blogPostContent
}) => {
    console.log(blogPostContent)
    return (
        <BlogPostItemWrapper>
            <h1>{blogPostTitle}</h1>
            <BlogPostItemImageWrapper>
                <BlogPostImage fixed={blogPostImage} />
            </BlogPostItemImageWrapper>
            <BlogPostItemContentWrapper>
                <h4>
                    {authorName}, {authorTitle}
                </h4> 
     
                {blogPostContent.map((value, index) => {
                return <p key={index}>{value}</p>
                })}
          
            </BlogPostItemContentWrapper>
        </BlogPostItemWrapper>
    )
}

export default BlogPostItem
