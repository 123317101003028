import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import BlogPostItem from '../components/blogPostItem'
import {graphql} from 'gatsby'

const HomeLink = styled.a`
    color: white;
    text-decoration: none;
    font-size: 1.2em;
    margin-left: 10px;
    transition: 0.3s;

    &:hover {
        color: #C58BC3;
    }
`

const BlogPostTemplate = (props) => {
    console.log(props.data)
    return (
        <Layout>
            <HomeLink href="/">← Back to all Blog Posts</HomeLink>
            <BlogPostItem
                authorName={props.data.blogPost.author.name}
                authorTitle={props.data.blogPost.author.title}
                blogPostImage={props.data.blogPost.localImage.childImageSharp.fixed}
                blogPostContent={props.data.blogPost.content}
                blogPostTitle={props.data.blogPost.title}
            />
        </Layout>
    )
}

export const query = graphql`
    query blogPostQuery($blogPostId: String!) {
        blogPost(id: {eq: $blogPostId}) {
            id
            title
            localImage {
                childImageSharp {
                fixed(width: 400) {
                    ...GatsbyImageSharpFixed
                }
                }
            }
            author {
                name
                title
            } 
            content
            excerpt
      }
    }
`

export default BlogPostTemplate
